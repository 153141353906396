/* eslint-disable react/no-array-index-key */
import { css } from '@emotion/react';
import React from 'react';
import t from 'react-translate';
import { halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { baseComponentStyles } from 'lecture_pages/directives/components/base-lecture-component';
import { tealBlue } from 'styles/global_defaults/colors';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { useSelector } from 'react-redux';
import { AIDiscussionInsights, PUSHER_DISCUSSION_INSIGHTS } from 'redux/schemas/models/post';
import { getAIDiscussionInsights } from 'redux/selectors/posts';
import NvUserAvatar from 'components/nv-user-avatar';
import { AngularServicesContext, ExposedAngularServices } from 'react-app';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import { Comment } from 'redux/schemas/models/comment';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import { CombinedPost } from 'redux/schemas';
import pusherService from 'shared/services/pusher-service';
import humps from 'humps';
import { useAppDispatch } from 'redux/store';
import { getKeyThemes, getPerspectives } from 'redux/actions/posts';
import { isEmpty } from 'underscore';
import { handheld } from 'styles/global_defaults/media-queries';
import { TextLoader, GridLoader } from '../left-panel/novo-ai/components/shared/loaders';
import GradientAiIcon from '../left-panel/novo-ai/components/shared/gradient-ai-icon';
import { config } from '../../../../config/pendo.config.json';

// Getting the discussion comment width, which is the width for all the components of the discussion insights project
const userBadgeWidth = 80;
const leftPadding = halfSpacing;
export const discussionCommentWidth = `calc(100% - ${userBadgeWidth}px - ${leftPadding}px)`;

/**
 * Badge Component - It's shown only to the admin user
 */
type BadgeProps = {
  label: string;
  tooltip: string;
};

const badgeStyles = css`
  padding: 2px ${quarterSpacing}px;
  border-radius: ${quarterSpacing}px;
  background: linear-gradient(48.41deg, rgba(96, 243, 171, 0.1) 0%, rgba(0, 204, 188, 0.1) 45%, rgba(0, 154, 192, 0.1) 100%);
  color: ${tealBlue};
  cursor: default;
`;

const Badge: React.FC<BadgeProps> = ({ label, tooltip }) => (
  <NvTooltip
    enabled={!!tooltip}
    text={tooltip}
    placement='top'
  >
    <span css={badgeStyles} className='text-small font-weight-bold'>
      {label}
    </span>
  </NvTooltip>
);

/**
 * Perspective Component - It's shown only to the learner user
 */
type PerspectiveProps = {
  title: string;
  topic: CombinedPost;
  comment: Partial<Comment>;
};

const perspectiveStyles = css`
  position: relative;
  cursor: pointer;
  padding: ${quarterSpacing}px ${halfSpacing}px;
  padding-left: ${halfSpacing + 4}px;
  border-radius: ${quarterSpacing}px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${halfSpacing}px;
  transition: background-color 0.218s ease-in;

  :hover {
    background-color: #00CCBC0D;
  }

  ::before {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: linear-gradient(48.41deg, #60F3AB 0%, #00CCBC 45%, #009AC0 100%);
  }

  .left {
    display: flex;
    gap: ${halfSpacing}px;

    .nv-user-avatar {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

const Perspective: React.FC<PerspectiveProps> = ({ title, topic, comment }) => {
  // Using the deprecated Context as required by the ResponsivelyEmbeddedAngularHTML component
  const angularServices: ExposedAngularServices = React.useContext(AngularServicesContext);
  const { catalogId } = useSelector(getCurrentCourse);

  const commentLink = React.useMemo(() => angularServices.$state.href(
    'comment-direct-link',
    {
      catalogId,
      topicId: topic?.forumId,
      postId: topic?.id,
      commentId: comment.id,
    },
  ), [comment.id]);

  return (
    <section>
      <h6 className='text-regular font-weight-bolder m-0 mb-2'>
        {title}
      </h6>

      <a
        css={perspectiveStyles}
        href={commentLink}
        rel='noreferrer'
        target='_blank'
      >
        <div className='left'>
          <NvUserAvatar
            tooltipEnabled={false}
            directToProfile={false}
            displayRoleBadge={false}
            user={comment.user}
            size='md'
            borderType='round'
          />

          <div>
            <p className='text-body font-weight-bolder m-0'>
              {comment.user.fullName}:
            </p>

            <ResponsivelyEmbeddedAngularHTML
              template={comment.body}
              angularServices={angularServices}
            />
          </div>
        </div>

        <NvIcon
          icon='arrow-right'
          size='xs-smallest'
          className='gray-2'
        />
      </a>
    </section>
  );
};

/**
 * Discussion Insights - It's shown to both admin and learner users with different content
 */
type DiscussionInsightsProps = {
  isLearner: boolean;
  topic: CombinedPost;
  hasAiInsights: boolean;
};

const styles = css`
  width: ${discussionCommentWidth};
  padding: ${standardSpacing}px;
  border-radius: ${standardSpacing}px;
  background: linear-gradient(48.41deg, rgba(96, 243, 171, 0.05) 0%, rgba(0, 204, 188, 0.05) 45%, rgba(0, 154, 192, 0.05) 100%);
  display: flex;
  flex-direction: column;
  gap: ${standardSpacing}px;

  ${handheld(css`
    width: 100%;
  `)};

  .header, .text-loader, .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header {
    .text-loader {
      gap: ${quarterSpacing}px;
    }

    .right {
      gap: ${standardSpacing}px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: ${standardSpacing}px;
  }
`;

export const DiscussionInsights: React.FC<DiscussionInsightsProps> = (
  {
    isLearner,
    hasAiInsights,
    topic,
  },
) => {
  const topicId = topic.id;

  const dispatch = useAppDispatch();
  const { id: courseId } = useSelector(getCurrentCourse);
  const { learnersAliases } = useSelector(getCourseAliases);
  const discussionInsights: AIDiscussionInsights = useSelector(
    (state) => getAIDiscussionInsights(state, topicId),
  );
  const [isExpanded, setIsExpanded] = React.useState(true);

  const keyThemesLoading = discussionInsights?.keyThemes?.loading || discussionInsights?.keyThemes?.subscribeToPusher;
  const perspectivesLoading = discussionInsights?.perspectives?.loading;

  // Listening for changes in the discussion key themes
  React.useEffect(() => {
    let pusherChannel = null;
    if (discussionInsights?.keyThemes?.subscribeToPusher) {
      pusherChannel = pusherService.initializeCourseChannel(courseId);
      pusherChannel.bind(PUSHER_DISCUSSION_INSIGHTS, (data) => {
        console.log('DiscussionInsights pusherData ::: ', data);
        const pusherData = humps.camelizeKeys(data) as unknown as { courseId: number, topicId: number };
        if (courseId === pusherData.courseId && topicId === pusherData.topicId) {
          dispatch(getKeyThemes({ topicId }));
          pusherChannel.unbind(PUSHER_DISCUSSION_INSIGHTS);
        }
      });
    }

    return () => {
      if (pusherChannel) pusherChannel.unbind(PUSHER_DISCUSSION_INSIGHTS);
    };
  }, [discussionInsights?.keyThemes?.subscribeToPusher]);

  // Getting the perspectives of the first comment
  React.useEffect(() => {
    if (isLearner && discussionInsights?.firstCommentId) {
      dispatch(getPerspectives({
        topicId,
        commentId: discussionInsights?.firstCommentId,
      }));
    }
  }, [isLearner, discussionInsights?.firstCommentId]);

  // Collapsible behavior
  const arrowDownStyles = css`
    transform: rotate(${isExpanded ? '-180deg' : '0deg'});
    transition: transform 0.218s ease-in;
  `;

  // If there is no content to show, don't render the component
  if (isLearner && discussionInsights?.keyThemes?.data?.length === 0 && isEmpty(discussionInsights?.perspectives.similar) && isEmpty(discussionInsights?.perspectives.different)) {
    return null;
  }

  return (
    <section css={baseComponentStyles} className='d-flex justify-content-end'>
      <div css={styles}>
        <div className='header'>
          <div className='left'>
            <TextLoader animate={keyThemesLoading || perspectivesLoading}>
              <GradientAiIcon icon='gen-ai' size='small' />
              <h6 className='text-body font-weight-bolder m-0'>
                {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.TITLE()}
              </h6>
            </TextLoader>
          </div>

          <div className='right'>
            {!(hasAiInsights || isLearner) && (
              <Badge
                label={t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.ADMIN_ONLY()}
                tooltip={t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.ADMIN_ONLY_TOOLTIP({ learnersAlias: learnersAliases.learnersAlias })}
              />
            )}
            <ClickableContainer
              className='d-flex align-items-center justify-content-center'
              aria-expanded={isExpanded}
              aria-controls='discussion_insights_body'
              onClick={() => setIsExpanded(!isExpanded)}
              data-qa={isExpanded ? config.pendo.lectureEdit.aiDiscussionInsights.collapse : config.pendo.lectureEdit.aiDiscussionInsights.expand}
            >
              <NvIcon
                icon='arrow-down'
                size='small'
                className='bold'
                css={arrowDownStyles}
              />
            </ClickableContainer>
          </div>
        </div>

        {isExpanded && (
          <div id='discussion_insights_body' className='body'>
            {(keyThemesLoading && perspectivesLoading) && (
              <>
                {isLearner && (
                  <span className='m-0 text-body bold'>
                    {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.LEARNER_SUBTITLE()}
                  </span>
                )}
                <GridLoader />
              </>
            )}
            {(keyThemesLoading && !perspectivesLoading) ? (
              <>
                {isLearner && (
                  <span className='m-0 text-body bold'>
                    {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.LEARNER_SUBTITLE()}
                  </span>
                )}
                <GridLoader />
              </>
            ) : (
              <>
                {discussionInsights?.keyThemes?.data?.length > 0 && (
                  <>
                    {isLearner && (
                      <span className='m-0 text-body bold'>
                        {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.LEARNER_SUBTITLE()}
                      </span>
                    )}
                    <div className='key-themes'>
                      <h6 className='text-regular font-weight-bolder m-0 mb-2'>
                        {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.KEY_THEMES()}
                      </h6>
                      <ul className='m-0'>
                        {discussionInsights?.keyThemes?.data?.map((keyTheme, index) => (
                          <li
                            key={`key-theme-${index}`}
                            className='text-body'
                          >
                            {keyTheme}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </>
            )}
            {(!keyThemesLoading && perspectivesLoading) ? (
              <GridLoader />
            ) : (
              <>
                {isLearner && !isEmpty(discussionInsights?.perspectives.similar) && (
                  <Perspective
                    title={t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.SIMILAR_PERSPECTIVE()}
                    topic={topic}
                    comment={discussionInsights?.perspectives.similar}
                  />
                )}

                {isLearner && !isEmpty(discussionInsights?.perspectives.different) && (
                  <Perspective
                    title={t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.DIFFERENT_PERSPECTIVE()}
                    topic={topic}
                    comment={discussionInsights?.perspectives.different}
                  />
                )}
              </>
            )}
            {!isLearner && !keyThemesLoading && hasAiInsights && (
              <p className='m-0 text-small font-weight-bold text-gray-2'>
                {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.NOT_ADMIN_ONLY({ LearnersAlias: learnersAliases.LearnersAlias })}
              </p>
            )}
            {isLearner && !perspectivesLoading && discussionInsights?.perspectives?.errorCode && (
              <p className='m-0 text-small font-weight-bold text-gray-2'>
                {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.ERROR_PERSPECTIVE()}
              </p>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default DiscussionInsights;
