import store from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { enableInsightsToLearners } from 'redux/actions/posts';
import t from '../../../react-translate';

export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    linkedEditMode: '<',
    reorderMode: '<',
    index: '<',
    context: '<',
  },
  controller: function ctrl(
    $controller,
    CurrentCourseManager,
    LectureComponentsHelper,
    InstitutionsManager,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;

    vm.institutionsManager = InstitutionsManager;
    vm.institutionsManager.initialize(vm.CurrentUserManager.currentInstitution.id);

    /* Admin Actions */
    vm.editBasics = () => {
      vm.lectureComponent.createDraft();

      const modalInstance = LectureComponentsHelper.showEditModal(vm.lectureComponent);

      modalInstance.result.then(() => {
        const closeModal = LectureComponentsHelper.showSavingOverlay();

        vm.lectureComponent.saveDraft().then(closeModal, closeModal);
      });
    };

    vm.saveTitleOnBlur = () => {
      vm.lectureComponent.save();
    };

    vm.isDiscussionEditDisabled = function () {
      return vm.institutionsManager.institution.disableDiscussionEdits;
    };

    vm.setEditMenu = () => {
      const { sharedProps } = vm.context;
      const extraOptions = [];

      // Adding the edit discussion option
      extraOptions.push(sharedProps.extraOptions.getEditOption(t.LECTURE_PAGES.COMPONENTS.DISCUSSION.EDIT_BASICS()));

      // Adding the discussion insights option
      if (vm.institutionsManager.institution?.hasAiComponentGeneration) {
        const currentCourseIsCollection = vm.CurrentCourseManager?.course?.isContentManagementCollection;

        extraOptions.push({
          type: 'switch',
          id: 'has_ai_insights',
          label: t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.SETTINGS.ENABLE_TO_LEARNERS({
            learnersAlias: CurrentCourseManager.course.learnersName.downcasedPluralized,
          }),
          value: `lectureComponent${vm.lectureComponent.id}.topic.hasAiInsights`,
          onChange: (value) => {
            wrapThunkAction(
              store.dispatch(enableInsightsToLearners({
                discussionId: vm.lectureComponent?.id,
                topicId: vm.lectureComponent?.topic?.id,
                hasAiInsights: value,
              })),
            ).then((action) => {
              if (action.payload) {
                vm.lectureComponent.topic.hasAiInsights = value;
              }
            });
          },
          disabled: currentCourseIsCollection,
          tooltip: {
            enabled: currentCourseIsCollection,
            text: t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.SETTINGS.TOOLTIP(),
            placement: 'left',
            textAlign: 'left',
          },
        });
      }

      sharedProps.extraOptions = {
        ...sharedProps.extraOptions,
        ...{
          options: extraOptions,
        },
      };
    };

    vm.setEditMenu();
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-discussion-lecture-component.html',
};
