import store from 'redux/store';
import {
  resetDiscussionInsights,
  updateDiscussionInsights,
  getPerspectives,
} from 'redux/actions/posts';

/* @ngInject */
export default function PostModelService(
  PostsResources,
  _,
  $sce,
  moment,
  CommentModel,
  CurrentUserManager,
  $state,
  $q,
  LectureVideoModel,
  ReportModel,
) {
  const PostModel = function (attributes) {
    const _this = this;

    _.extend(_this, attributes);

    preprocess(attributes);

    /** Public Data * */
    _this.comments = attributes?.comments || [];
    _this.commentsDeletedByUser = [];
    _this.defaultCommentsPageSize = 5;
    _this.loadMorePageSize = 5;
    _this.commentsFetched = false;

    _this.sortedByLikes = false;

    /** Public Functions * */

    /** Public Functions * */

    _this.updateComment = updateComment;
    _this.removeComment = removeComment;


    _this.remove = remove;
    _this.hide = hide;
    _this.highlight = highlight;
    _this.unHighlight = unHighlight;
    _this.like = like;
    _this.unlike = unlike;
    _this.follow = follow;
    _this.unfollow = unfollow;
    _this.report = report;
    _this.getLikersInfo = getLikersInfo;
    _this.trustPostContent = trustPostContent;
    _this.findMemberByUserId = findMemberByUserId;
    _this.fetchCommentsForPost = fetchCommentsForPost;
    _this.belongsToCurrentUser = belongsToCurrentUser;
    _this.userHasRemovedComment = userHasRemovedComment;
    _this.addMeetingRsvp = addMeetingRsvp;
    _this.updateMeetingRsvp = updateMeetingRsvp;
    _this.formatPostDate = formatPostDate;


    /** Private Functions * */
    function preprocess(preprocessAttributes) {
      _this.topicId = _this.forumId;

      if (!_this.lectureVideo && _this.metaContent?.video) {
        _this.lectureVideo = new LectureVideoModel(_this.metaContent, _this.catalogId, null, 0);
      }

      PostModel.normalizePostData(_this, { catalogId: _this.catalogId }, false, true);

      _this.highlighted = isHighlighted();
    }

    function updateComment(comment) {
      return _.extend(comment, { postId: _this.id }).update().then((response) => {
        const updatedComment = new CommentModel(_.extend(response.result, { catalogId: _this.catalogId, owner: _this, replyCount: response.result.commentsCount }));
        const indexOfExisting = _.findIndex(_this.comments, { id: updatedComment.id });
        _this.comments.splice(indexOfExisting, 1, updatedComment);
        return response.result;
      });
    }

    function removeComment(comment) {
      _this.commentsDeletedByUser.push(comment.id);

      return comment.remove().then((deletedComment) => {
        _this.comments.splice(_.findIndex(_this.comments, { id: comment.id }), 1);
        _this.numCommentsAndReplies -= (comment.replyCount + 1);
        _this.commentedByUser = _.some(_this.comments, (c) => c.user.id === CurrentUserManager.user.id);

        if (deletedComment.owner) {
          _this.progress = deletedComment.owner.progress;
          _this.pointsReceived = deletedComment.owner.pointsReceived;
        }

        const member = findMemberByUserId(comment.user.id);
        if (member) {
          member.totalContributions -= 1;
        }

        if (comment.replies.length) {
          comment.replies.forEach(reply => {
            const replyMember = findMemberByUserId(reply.user.id);
            if (replyMember) {
              replyMember.totalContributions -= 1;
            }
          });
        }

        // Getting the updated perspectives of the learner comments in the discussion
        if (comment?.owner?.hasAiInsights && comment?.owner?.hasMinValidCommentsForKeyThemes) {
          const state = store.getState();
          const topicId = comment?.owner?.id;
          const firstCommentId = state.app.generatedAIContent.discussionInsights[topicId]?.firstCommentId;
          const updatedFirstPostContributionId = deletedComment?.owner?.firstPostContributionId;

          store.dispatch(updateDiscussionInsights({
            topicId,
            data: {
              firstCommentId: updatedFirstPostContributionId,
            },
          }));

          if (updatedFirstPostContributionId && (firstCommentId !== updatedFirstPostContributionId)) {
            store.dispatch(getPerspectives({
              topicId,
              commentId: updatedFirstPostContributionId,
            }));
          }

          if (!updatedFirstPostContributionId && (firstCommentId === deletedComment?.id)) {
            store.dispatch(resetDiscussionInsights({
              topicId,
            }));
          }
        }
      });
    }


    function remove() {
      return PostsResources.delete(
        {
          catalogId: _this.catalogId,
          id: _this.id,
        },
      ).$promise;
    }

    function hide() {

    }

    function isHighlighted() {
      _this.highlighted = _this.highlightedFrom && _this.highlightedTo;
      return _this.highlighted;
    }

    function highlight(highlightInfo) {
      return PostsResources.highlight({
        catalogId: _this.catalogId,
        id: _this.id,
        from: highlightInfo.startDate,
        to: highlightInfo.endDate,
      }).$promise.then((response) => {
        if (_this.newPost) {
          _this.newPost = false; // the new badge overlaps the highlight badge, so remove it
        }
        _this.highlightedNow = response.result.highlightedNow;
        _this.highlighted = true;
        _this.highlightedFrom = response.result.highlightedFrom;
        _this.highlightedTo = response.result.highlightedTo;
      });
    }

    function unHighlight() {
      return PostsResources.unHighlight({
        catalogId: _this.catalogId,
        id: _this.id,
      }).$promise.then(() => {
        _this.highlightedNow = false;
        _this.highlightedFrom = null;
        _this.highlightedTo = null;
        _this.highlighted = false;
      });
    }

    function like() {
      return PostsResources.like({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then((response) => {
        _this.liked = true;
        _this.votesCount = response.result.numLikes;
      });
    }

    function unlike() {
      return PostsResources.unlike({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then((response) => {
        _this.liked = false;
        _this.votesCount = response.result.numLikes;
      });
    }

    function follow() {
      return PostsResources.follow({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then((response) => {
        _this.followed = true;
      });
    }

    function unfollow() {
      return PostsResources.unfollow({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then((response) => {
        _this.followed = false;
      });
    }

    function report() {
      return PostsResources.report({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then(() => {
        _this.flagged = true;
      });
    }

    function getLikersInfo() {
      return PostsResources.votersInfo({
        catalogId: _this.catalogId,
        id: _this.id,
        page: 1,
      }).$promise.then((response) => {
        _this.likers = response.result;
      });
    }

    function trustPostContent() {
      _this.body = $sce.trustAsHtml(_this.body);
      _.map(_this.comments, (comment, index) => {
        _this.comments[index].body = $sce.trustAsHtml(comment.body);
      });
    }

    function findMemberByUserId(userId) {
      return _.find(_this.teamDiscussionMembers, (member, index) => member.user.id === userId);
    }

    function fetchCommentsForPost(sortByLikes, betweenId, pageSize = _this.defaultCommentsPageSize) {
      _this.sortedByLikes = sortByLikes;
      return CommentModel.getCommentsList({
        catalogId: _this.catalogId,
        postId: _this.id,
        owner: _this,
        order: sortByLikes ? 'likes' : null,
        pageSize,
        betweenId,
        vieweeLastActivity: this.vieweeLastActivity,
      }).then((response) => {
        _this.comments = response.commentsList;
        _this.commentsFetched = true;

        if (response.additionalPostsBeforeCount !== null || sortByLikes || betweenId) {
          _this.additionalCommentsBeforeCount = response.additionalPostsBeforeCount;
          _this.additionalNewCommentsBeforeCount = response.additionalNewPostsBeforeCount;
        }

        if (response.additionalPostsAfterCount !== null || (!sortByLikes && !betweenId)) {
          _this.additionalCommentsAfterCount = response.additionalPostsAfterCount;
        }

        return response.commentsList;
      });
    }

    function belongsToCurrentUser() {
      return _this.user && CurrentUserManager.user.id === _this.user.id;
    }

    function userHasRemovedComment(commentId) {
      return _.contains(_this.commentsDeletedByUser, commentId);
    }

    function addMeetingRsvp(meetingRsvpData, teamMemberId) {
      // sanity check to make sure the meeting actually exists
      if (_this.metaContent?.attendance && !_.findWhere(_this.metaContent.attendance, { teamMemberId })) {
        _this.metaContent.attendance.push({ id: meetingRsvpData.id, rsvp: meetingRsvpData.rsvp, teamMemberId });
      }
    }

    function updateMeetingRsvp(meetingRsvpData, teamMemberId) {
      if (_this.metaContent?.attendance) {
        const existingRsvpIndex = _.findIndex(_this.metaContent.attendance, { teamMemberId });

        const updatedRsvp = _.extend(_this.metaContent.attendance[existingRsvpIndex], meetingRsvpData);

        _this.metaContent.attendance.splice(existingRsvpIndex, 1, updatedRsvp);
      }
    }

    function formatPostDate() {
      return moment(_this.createdAt).format(moment().isSame(_this.createdAt, 'year') ? 'MOMENT.MONTH_DAY_AT_TIME' : 'MOMENT.MONTH_DAY_COMMA_YEAR_AT_TIME');
    }
  };

  PostModel.normalizePostData = function (postData, dataToAdd, shouldCloneData, transformPropertiesInPlace) {
    const fromBackend = shouldCloneData ? _.clone(postData) : postData;
    const newProperties = _.extend(
      fromBackend,
      {
        catalogId: dataToAdd.catalogId,
        numCommentsAndReplies: fromBackend.numPostsAndComments,
        commentedByUser: fromBackend.posted,
        topicId: fromBackend.forumId,
      },
      dataToAdd.owner ? { owner: dataToAdd.owner } : {},
      dataToAdd.lectureVideo ? { lectureVideo: dataToAdd.lectureVideo } : {},
    );

    if (postData.metaContent?.type === 'report') {
      postData.metaContent = ReportModel.normalizeSubmissionData(postData.metaContent);
    }

    if (transformPropertiesInPlace) {
      return newProperties;
    }
    return new PostModel(newProperties);
  };

  /*
      searchParams = {
        sortOrder: manager.sortOrders[0],
        filterOption: manager.filterOptions[0],
        selectedTopic: allTopicsOption,
        queryTerm: null,
        page: 1
      };
    */
  PostModel.getPosts = function (catalogId, searchParams) {
    const payload = {
      catalogId,
      page: searchParams.page,
    };

    payload.order = searchParams.sortOrder.value;

    if (!searchParams.filterOption.ignoreInSearch) {
      payload.filter = searchParams.filterOption.value;
    }

    if (searchParams.queryTerm) {
      payload.queryTerm = searchParams.queryTerm;
    }

    if (!searchParams.selectedTopic.ignoreInSearch) {
      payload.forumId = searchParams.selectedTopic.id;
    }

    return PostsResources.search(payload).$promise.then((resource) => _.map(resource.result, (post) => {
      if (searchParams.selectedTopic.ignoreInSearch) {
        return PostModel.normalizePostData(post, { catalogId }, true);
      }
      return PostModel.normalizePostData(post, { catalogId, owner: searchParams.selectedTopic }, false);
    }));
  };


  PostModel.getSinglePost = function (catalogId, topicId, postId) {
    return PostsResources.getSinglePost({ catalogId, id: postId }).$promise.then(
      (response) => PostModel.normalizePostData(response.result, { catalogId }),
    );
  };

  PostModel.create = function (params) {
    return PostsResources.save({
      topicId: params.topic.id,
      catalogId: params.catalogId,
    },
    {
      topic: {

        title: params.title,
        body: params.body,
        forumId: params.topic.id,
        releaseDate: params.releaseDate || null,
        metaContent: null, // ??
        starterId: params.starterId || null,
      },
      mentionedIds: params.mentionedIds,
    }).$promise;
  };

  PostModel.update = function (params) {
    return PostsResources.update({
      catalogId: params.catalogId,
      id: params.id,
    }, {
      catalogId: params.catalogId,
      topicId: params.topicId,
      topic: {
        title: params.title,
        body: params.body,
        forumId: params.topic.id,
        releaseDate: params.releaseDate || null,
        metaContent: null, // ??
        starterId: params.starterId || null,
      },

    }).$promise;
  };

  return PostModel;
}
