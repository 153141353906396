import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { removeTagLectureComponent, tagLectureComponent } from 'redux/actions/skill-tags';
import { RootState } from 'redux/schemas';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { useAppDispatch } from 'redux/store';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import NvTooltip from 'shared/components/nv-tooltip';
import { gray2, primary } from 'styles/global_defaults/colors';
import { largeSpacing } from 'styles/global_defaults/scaffolding';
import { RolesService, PermissionTypes } from 'institutions/services/roles-service';
import { Enrollment } from 'redux/schemas/models/my-account';
import { VideoPracticeActivity, VideoPracticeScenario } from 'redux/schemas/models/video-practice';
import { textSmallFontSize, textXSFontSize } from 'styles/global_defaults/fonts';
import { getCurrentCourse } from 'redux/selectors/course';
import NvRoundButton from 'shared/components/nv-round-button';
import { discussionCommentWidth } from 'lecture_pages/components/discussion/discussion-insights';
import { baseComponentStyles } from 'lecture_pages/directives/components/base-lecture-component';
import { handheld } from 'styles/global_defaults/media-queries';
import SkillsButtonField, { SkillButtonType } from './skill-button-field';
import SkillItem from './skill-item';
import SkillsModal from './skills-modal';
import { config } from '../../../config/pendo.config.json';

const styles = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin-top: ${largeSpacing}px;
  margin-bottom: ${largeSpacing}px;
  .warning-text{
    font-size: ${textSmallFontSize}px;
    color: ${gray2};
  }
  .skill-tags{
    display: flex;
    margin-top: ${largeSpacing}px;
    max-width: 800px;
    flex-wrap :wrap;
    padding: 0 25px;
    justify-content: center;
    align-items: center;
  }
`;

type SkilltagsProps = {
  catalogId: string,
  lecturePageId: number,
  lectureComponentId: number,
  lectureComponentName: string
};

const SkillsTags = ({ catalogId, lecturePageId, lectureComponentId, lectureComponentName }: SkilltagsProps) => {
  const [showModal, setShowModal] = useState(false);
  // Video Practice skill tags when this lecture component is added
  const skillTaggings = useSelector((state) => state.models.lectureComponents[lectureComponentId]?.videoPractice?.scenario?.skillTaggings) || [];
  const videoPracticeTags = useSelector((state) => skillTaggings.map((skillTagging) => state.models.skillTags[skillTagging?.skillTagId]));
  // component tags
  const componentTags = useSelector((state) => state.models.lectureComponents[lectureComponentId]?.skillTags) || videoPracticeTags;
  const currentCourse = useSelector(getCurrentCourse);

  let isDisabled = false;
  let showDuplicatedWarning = false;

  // special case: if a video practice component was created in another course where current users is not an admin
  if (lectureComponentName === ComponentType.VIDEO_PRACTICE) {
    const practiceActivityId = useSelector((state) => state.models.lectureComponents[lectureComponentId].practiceActivity);
    const activity = useSelector<RootState, VideoPracticeActivity>((state) => state.models.practiceActivities[practiceActivityId]);
    const scenario = useSelector<RootState, VideoPracticeScenario>((state) => state.models.practiceScenarios[activity.scenarioId]);
    const enrollmentForScenario = useSelector<RootState, Enrollment>((state) => Object.values(state.models.enrollments).find((enrl: any) => state.models.courses[enrl.courseCatalogId]?.catalogId === scenario.createdInCourse?.catalogId));
    if (!(RolesService.hasPermission(enrollmentForScenario?.roles.permission, PermissionTypes.COURSE_BUILDER) || enrollmentForScenario?.roles.admin)) {
      isDisabled = true;
    }
    if (!isDisabled && scenario.numCoursesUsedIn > 1) {
      showDuplicatedWarning = true;
    }
  }

  // full list of institution tags
  const allTags = Object.values(useSelector((state) => state.models.skillTags));
  // allTags - componentTags = tags to be displayed in autocomplete
  const availableTags: any = allTags.filter((tag: any) => componentTags.every((item: any) => item.id !== tag.id));

  const dispatch = useAppDispatch();
  const checkErrors = (result: any) => {
    if (result.error) {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
    }
  };
  const doAddSkill = (id) => {
    dispatch(tagLectureComponent({ catalogId, lecturePageId, lectureComponentId, lectureComponentName, skillTagId: id }))
      .then((result: any) => {
        checkErrors(result);
      });
  };
  const doDeleteSkill = (id) => {
    dispatch(removeTagLectureComponent({ catalogId, lecturePageId, lectureComponentId, lectureComponentName, skillTagId: id }))
      .then((result: any) => {
        checkErrors(result);
      });
  };

  // If it's a discussion component, setting the width of the skill tags container based on the comment width
  const containerStyles = css`
    ${baseComponentStyles};
    ${lectureComponentName === ComponentType.TOPIC && css`
      display: flex;
      justify-content: flex-end;

      .skill-tags-container {
        width: ${discussionCommentWidth};

        ${handheld(css`
          width: 100%;
        `)};
      }
    `}
  `;

  const iconButtonStyles = css`
    font-size: ${textXSFontSize}px;
    color: ${primary};
    display: inline;
    &.nv-icon-button {
      background-color: transparent;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      padding-left: 5px;
    }
  `;

  return (
    allTags?.length > 0
    && !currentCourse.isContentManagementCollection && (
      <section css={containerStyles}>
        <div css={styles} className='skill-tags-container'>
          <div className='info-section'>
            <span> {t.INSTITUTIONS.SKILL_TAGS.RELATED_SKILLS()}</span>
            <NvTooltip text={t.INSTITUTIONS.SKILL_TAGS.VIEW_FULL_LIST()}>
              <span>
                <NvRoundButton
                  css={iconButtonStyles}
                  icon='icon-info'
                  label=''
                  onClick={() => setShowModal(true)}
                  className='small-size'
                  pendoTag={config.pendo.skillTags.viewFull}
                  ariaLabel={t.INSTITUTIONS.SKILL_TAGS.VIEW_FULL_LIST()}
                />
              </span>
            </NvTooltip>
            <NvModal
              show={showModal}
              width={720}
              type={ModalType.DYNAMIC}
              fullHeight={false}
              onClose={() => setShowModal(false)}
              header={t.INSTITUTIONS.SKILL_TAGS.FULL_LIST()}
              body={(<SkillsModal />)}
            />
          </div>
          {showDuplicatedWarning && <div className='warning-text ml-5 mr-5 mt-4'>{t.INSTITUTIONS.SKILL_TAGS.ACTIVITY_DUPLICATED()}</div>}
          <div className='skill-tags'>
            {componentTags.map((tag) => <SkillItem key={tag.id} onDelete={(id) => doDeleteSkill(id)} tag={tag} />)}
            {availableTags.length > 0 && <SkillsButtonField tags={availableTags} disabled={isDisabled} type={componentTags.length > 0 ? SkillButtonType.Compact : SkillButtonType.Normal} onAddSkill={(id) => doAddSkill(id)} />}
          </div>
        </div>
      </section>
    )
  );
};

export default SkillsTags;
