import { css } from '@emotion/react';
import { BaseLectureComponent, getLectureComponentDomId } from 'lecture_pages/directives/components/base-lecture-component';
import NvAddComponent from 'lecture_pages/templates/components/nv-add-component';
import React, { useCallback, useContext, useRef, useState, forwardRef, useEffect } from 'react';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import {
  isTemporaryLectureComponent, ComponentType, RichTextType, ExternalToolType,
  StyledLinkType, AccordionSectionType,
  LectureComponent,
} from 'redux/schemas/models/lecture-component';
import SkillsTags from 'skill_tags/components/skill-tags';
import useErrorBoundary from 'use-error-boundary';
import { useLecturePageFromParams, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { AsyncContentProvider } from 'shared/hooks/use-async-content';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import some from 'lodash/some';
import { mergeRefs } from 'shared/react-utils';
import { useAppDispatch } from 'redux/store';
import { checkScormStatus, lecturePageBottomReachedState } from 'redux/actions/lecture-pages';
import { gray3, gray4, gray6 } from 'styles/global_defaults/colors';
import NvCopyComponent from 'lecture_pages/templates/components/nv-copy-component';
import { isEmpty, keys, uniq } from 'underscore';
import NvIcon from 'shared/components/nv-icon';
import t from 'react-translate';
import CallOutBox from 'shared/components/call-out-box';
import { updateDiscussionInsights } from 'redux/actions/posts';
import { AIDiscussionInsights } from 'redux/schemas/models/post';
import { getAIDiscussionInsights } from 'redux/selectors/posts';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { LectureComponentProps, LecturePageMode } from '..';
import HeaderLectureComponent from '../header/header-lecture-component';
import WebLinkLectureComponent from '../web-link/web-link-lecture-component';
import ImageLectureComponent from '../image/image-lecture-component';
import RichTextLectureComponent from '../rich-text/rich-text-lecture-component';
import AngularLectureComponent, { denormLectureComponent, loadAngularLectureComponentModel } from './angular-lecture-component';
import StyledLinkLectureComponent from '../styled-link-lecture-component/styled-link-lecture-component';
import AccordionLectureComponent from '../accordion-lecture-component/accordion-lecture-component';
import VideoPracticeLectureComponent from '../video-practice/video-practice-lecture-component';
import TeamDiscussionLectureComponent from '../discussion/team-discussion';
import PollLectureComponent from '../polls-lecture-component/poll-lecture-component';
import ProfileCompletionLectureComponent from '../profile-completion-lecture-component';
import ExerciseSkillsRatingComponent from '../exercise-skills-rating/exercise-skills-rating-lecture-component';
import PublicPracticeFeedbackCriteriaLectureComponent from '../video-practice-evaluation/video-practice-evaluation-lecture-component';
import MeetAndGreetLectureComponent from '../meet-and-greet/meet-and-greet-lecture-component';
import WhiteSpaceLectureComponent from '../white-space-lecture-component';
import { LecturePagePreviewContext, PreviewSource } from '../lecture-page-preview-modal';
import ProgressiveQuizLectureComponent from '../progressive-quiz-lecture-component';
import DiscussionInsights from '../discussion/discussion-insights';
import GenerateDiscussionInsights from '../discussion/generate-discussion-insights';

/** Typing for a function component that renders a React lecture component */
type LectureComponentRenderFunc = (props: LectureComponentProps<any>) => JSX.Element;

export type ReactLectureComponentTypes =
  ComponentType.HEADER_STYLE1
  | ComponentType.HEADER_STYLE2
  | ComponentType.HEADER_STYLE3
  | ComponentType.IMAGE
  | RichTextType
  | ComponentType.POLL
  | StyledLinkType.BUTTON
  | StyledLinkType.CARD
  | AccordionSectionType
  | ComponentType.VIDEO_PRACTICE
  | ComponentType.TEAM_DISCUSSION
  | ComponentType.PROFILE_COMPLETION
  | ComponentType.MEET_AND_GREET
  | ComponentType.VIDEO_PRACTICE_FEEDBACK
  | ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK
  | ExternalToolType.WEB_LINK
  | ComponentType.EXERCISE_SKILLS_RATING
  | ComponentType.WHITE_SPACE
  | ComponentType.PROGRESSIVE_QUIZ;

/** Maps true types to the React Function Components used to render
 * This is used as the canonical list of which lecture components have been ported to React.
 * Notably, it's read by the new component workflows to determine whether they should use
 * React or Angular code when adding a new component */
// TODO: This should be a record so we require each type in ReactLectureComponentTypes to have a value here
export const reactComponentRenderFunctions: { [key in ReactLectureComponentTypes]?: LectureComponentRenderFunc } = {
  [ComponentType.HEADER_STYLE1]: HeaderLectureComponent,
  [ComponentType.HEADER_STYLE2]: HeaderLectureComponent,
  [ComponentType.HEADER_STYLE3]: HeaderLectureComponent,
  [ComponentType.IMAGE]: ImageLectureComponent,
  [ComponentType.POLL]: PollLectureComponent,
  [RichTextType.FULL]: RichTextLectureComponent,
  [RichTextType.SIMPLE]: RichTextLectureComponent,
  [StyledLinkType.BUTTON]: StyledLinkLectureComponent,
  [StyledLinkType.CARD]: StyledLinkLectureComponent,
  [AccordionSectionType.STYLE_1]: AccordionLectureComponent,
  [AccordionSectionType.STYLE_2]: AccordionLectureComponent,
  [AccordionSectionType.STYLE_3]: AccordionLectureComponent,
  [AccordionSectionType.STYLE_4]: AccordionLectureComponent,
  [AccordionSectionType.STYLE_5]: AccordionLectureComponent,
  [ComponentType.VIDEO_PRACTICE]: VideoPracticeLectureComponent,
  [ComponentType.VIDEO_PRACTICE_FEEDBACK]: PublicPracticeFeedbackCriteriaLectureComponent,
  [ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK]: PublicPracticeFeedbackCriteriaLectureComponent,
  [ComponentType.EXERCISE_SKILLS_RATING]: ExerciseSkillsRatingComponent,
  [ComponentType.TEAM_DISCUSSION]: TeamDiscussionLectureComponent,
  [ComponentType.PROFILE_COMPLETION]: ProfileCompletionLectureComponent,
  [ComponentType.MEET_AND_GREET]: MeetAndGreetLectureComponent,
  [ExternalToolType.WEB_LINK]: WebLinkLectureComponent,
  [ComponentType.WHITE_SPACE]: WhiteSpaceLectureComponent,
  [ComponentType.PROGRESSIVE_QUIZ]: ProgressiveQuizLectureComponent,
};

/** Renders all Lecture Components for the current Lecture Page state as a vertical list */
export const LectureComponentList = forwardRef((props, ref) => {
  const dispatch = useAppDispatch();
  const params = useLecturePageParams();
  const previewParams = useContext(LecturePagePreviewContext);
  const { $state } = useContext(AngularServicesContext);
  const isPreview = !isEmpty(previewParams?.source);
  const lecturePageId = isPreview && previewParams?.lecturePageId ? previewParams.lecturePageId : params.lecturePageId;

  const isCopyOrMove = previewParams?.source === PreviewSource.COPY || previewParams?.source === PreviewSource.MOVE;

  // TODO: This is getting rendered 4 times on page load. Seems wrong, fix
  const lecturePage = useLecturePageFromParams(lecturePageId);

  const lectureComponents = useSelector(state => {
    const savedComponents = lecturePage?.lectureComponents?.map(lcId => state.models.lectureComponents[lcId]) ?? [];
    const { temporaryComponents } = state.app.lecturePage;
    return [...Object.values(temporaryComponents), ...savedComponents].sort((c1, c2) => c1.index - c2.index);
  });

  const { integrations, creatorIds, onceLoaded, settings } = useSelector(state => state.app.liveSessions);

  const { externalTools, liveSessions } = useSelector(state => state.models);
  const currentInstitution = useSelector(getCurrentInstitution);

  const { CurrentPermissionsManager, $injector, $scope } = useContext(AngularServicesContext);

  /** Tracks whether we've fired the initial "scroll to activity" behavior on the current page navigation
   * The value is the ID of the last scrolled to activity. Used to avoid re-scrolling to the same activity */
  const [activityScrolledTo, setActivityScrolledTo] = useState(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const [isLectureContentLoaded, setIsLectureContentLoaded] = useState(false);
  const { ref: endRef, inView } = useInView();

  const mode = isPreview ? LecturePageMode.VIEW : params.mode;

  /**
   * When exiting from this page, a backend request is given to check the scorm
   * status if this page contains an external lecture component
   */
  useEffect(() => () => {
    if (some(lectureComponents, (lc) => lc.type === ComponentType.EXTERNAL_TOOL)) {
      dispatch(checkScormStatus({
        catalogId: params.catalogId,
        lecturePageId,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params.catalogId, lecturePageId]);

  // Here we are checking if the scroll position is reached to the bottom of the screen or not
  // and dispatching the function and setting the state accordingly.

  useEffect(() => {
    if (inView && isLectureContentLoaded) {
      dispatch(lecturePageBottomReachedState(true));
    } else {
      dispatch(lecturePageBottomReachedState(false));
    }
  }, [dispatch, inView, isLectureContentLoaded]);


  // Resetting the scroll to activity state when changing the lecture page.
  useEffect(() => () => {
    setActivityScrolledTo(null);
  }, [lecturePageId]);

  const focusFirstElement = ($element) => {
    let elements: any = $element.find('a[href], area[href], input:not([disabled]), '
      + 'button:not([disabled]),select:not([disabled]), textarea:not([disabled]), '
      + 'iframe, object, embed, *[tabindex], *[contenteditable=true]');
    elements = elements ? filter(elements, (element) => $(element).is(':visible')) : [];
    if (elements.length) {
      elements[0].focus();
    }
  };

  const scrollToElement = useCallback((activityId) => {
    if (!activityId) {
      return;
    }

    const targetActivity = lectureComponents.find(lc => !isTemporaryLectureComponent(lc) && lc.id === activityId);
    if (targetActivity) {
      setTimeout(() => {
        const lcElement = document.getElementById(getLectureComponentDomId(targetActivity));
        if (lcElement) {
          // lcElement.scrollIntoView({ behavior: 'smooth' });
          // focusFirstElement($(lcElement));
          ($('.main-panel-scrollable') as any).scrollToElementAnimated(lcElement, 40);
          focusFirstElement($(lcElement));
          setActivityScrolledTo(activityId);
        }
      }, 200);
    }
  }, [lectureComponents]);

  /** Scroll to the correct activity, if any is specified in the URL, after the components list has rendered at least once */
  const containerCbRef = useCallback(node => {
    if (node !== null) {
      containerRef.current = node;
    }

    if (activityScrolledTo !== params.activityId && node !== null) {
      const images: HTMLImageElement[] = node.querySelectorAll('img');

      const imagePromises = Array.from(images).map((image) => new Promise((resolve) => {
        if (image.complete && image.naturalHeight !== 0) {
          resolve(true);
          return;
        }

        const settleImageHandler = () => {
          resolve(true);
          image.removeEventListener('load', settleImageHandler);
          image.removeEventListener('error', settleImageHandler);
        };

        image.addEventListener('load', settleImageHandler);
        image.addEventListener('error', settleImageHandler);
      }));

      Promise.all(imagePromises).then(() => {
        scrollToElement(params.activityId);
      });
    }
  }, [activityScrolledTo, params.activityId, scrollToElement]);

  const onContentReady = useCallback(() => {
    if (activityScrolledTo !== params.activityId) {
      scrollToElement(params.activityId);
      setIsLectureContentLoaded(true);
    }
  }, [activityScrolledTo, params.activityId, scrollToElement]);

  /**
   * Skill Tags functionality
   */
  const componentHasSkillTags = useCallback((componentType, lectureComponent) => {
    switch (componentType) {
      case ComponentType.EXERCISE:
      case ComponentType.PRIVATE_PEER_EVALUATION:
      case ComponentType.PUBLIC_PEER_EVALUATION:
      case ComponentType.QUIZ:
      case ComponentType.PROGRESSIVE_QUIZ:
      case ComponentType.TEAM_DISCUSSION:
      case ComponentType.TIMED_QUIZ:
      case ComponentType.VIDEO_PRACTICE:
      case ComponentType.VIDEO:
      case ComponentType.TOPIC:
      case ComponentType.VIDEO_PRACTICE_FEEDBACK:
        return true;
      case ComponentType.EXTERNAL_TOOL:
        return externalTools[lectureComponent.externalTool]?.isTodo;
      case ComponentType.LIVE_SESSION:
        return liveSessions[lectureComponent.liveSession]?.isTodo;
      default:
        return false;
    }
  }, [externalTools, liveSessions]);

  const lecturePagePreviewClassName: string = $state?.current?.data?.mainClass?.toString()?.replace('lecture-page-edit', 'lecture-page-view') ?? 'lecture-page';

  const isLearner = CurrentPermissionsManager.isLearner();
  const isCourseBuilder = CurrentPermissionsManager.isCourseBuilder();
  const isMentor = CurrentPermissionsManager.isMentor();
  const isCourseAdmin = CurrentPermissionsManager.hasCourseAdminPermissions();
  const hasSkillTagsPermissions = () => mode === LecturePageMode.EDIT && isCourseBuilder;

  const showDiscussionInsights = (lectureComponent) => currentInstitution?.hasAiComponentGeneration && !params.currentCourseIsCollection && lectureComponent.type === ComponentType.TOPIC && (isCourseAdmin || isMentor || (isLearner && lectureComponent?.topic?.hasAiInsights));
  const showSkillTags = (lectureComponent) => componentHasSkillTags(lectureComponent.type, lectureComponent) && hasSkillTagsPermissions();

  return (
    <div
      /**
       * No style is used by the 'lecture-components-list' class. However, the
       * image lecture component was used this class for finding the width.
       */
      className={`lecture-components-list px-4 ${lecturePage.showTitle ? 'pt-4' : 'pt-6'}`}
      ref={mergeRefs(ref, containerCbRef)}
      css={css`
        padding-bottom: ${mode === LecturePageMode.EDIT ? 150 : 130}px;
        .disabled {
          position: relative;
          z-index: 1;
          .btn.btn-primary, .bs4-btn-primary {
            background-color: ${gray6};
            border-color: ${gray6};
            color: ${gray3};
          }
          .btn.btn-default, .bs4-btn-secondary {
            background-color: transparent;
            border-color: ${gray4};
            color: ${gray4}
          }
          .disabled-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 2;
          }
        }
      `}
    >
      <AsyncContentProvider onContentReady={onContentReady}>
        {lectureComponents.map((lectureComponent, index) => {
          const component: LectureComponentRenderFunc = ComponentContainer;

          // Profile completion LC using angular $scope's lectureComponent while using basic edit model. So adding the component data to angular $scope
          if ((mode === LecturePageMode.EDIT || mode === LecturePageMode.LINKED_EDIT)
            && (
              lectureComponent.trueType === ComponentType.PROFILE_COMPLETION
              || lectureComponent.trueType === ComponentType.TEAM_DISCUSSION
              || lectureComponent.trueType === ExternalToolType.WEB_LINK
              || lectureComponent.trueType === ComponentType.EXERCISE_SKILLS_RATING
              || lectureComponent.trueType === ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK
            )) {
            const denormedLC: any = cloneDeep(denormLectureComponent(lectureComponent, lecturePage));
            const ModelCtor = loadAngularLectureComponentModel($injector, denormedLC);
            const model = new ModelCtor(denormedLC, false, true);
            model.catalogId = params.catalogId;
            $scope[`lectureComponent${lectureComponent.id}`] = model;

            model.$scope = $scope;
          }

          return (
            <div key={lectureComponent.id}>
              <NvAddComponent
                enabled={mode === LecturePageMode.EDIT}
                noComponentsExist={!lectureComponents.length}
                index={index}
              />
              {isPreview ? (
                <React.Fragment>
                  {isCopyOrMove && (
                    <NvCopyComponent
                      index={index}
                      catalogId={params.catalogId}
                      currentLecturePageId={params.lecturePageId}
                    />
                  )}
                  <div className={`${lecturePagePreviewClassName} disabled`}>
                    <BaseLectureComponent
                      containerRef={containerRef}
                      currentLecturePage={lecturePage}
                      lectureComponent={lectureComponent}
                      RenderLectureComponent={component}
                    />
                    <div className='disabled-overlay' />
                  </div>
                </React.Fragment>
              ) : (
                <BaseLectureComponent
                  containerRef={containerRef}
                  currentLecturePage={lecturePage}
                  lectureComponent={lectureComponent}
                  RenderLectureComponent={component}
                />
              )}
              {showDiscussionInsights(lectureComponent) && (
                <section className='mt-4'>
                  <InsightsComponent discussionComponent={lectureComponent} isLearner={isLearner} />
                </section>
              )}
              {showSkillTags(lectureComponent) && (
                <SkillsTags
                  catalogId={params.catalogId}
                  lecturePageId={lecturePage.id}
                  lectureComponentId={lectureComponent.id}
                  lectureComponentName={lectureComponent.type}
                />
              )}
            </div>
          );
        })}
        {isLectureContentLoaded && <div ref={endRef} className='last-element' />}
      </AsyncContentProvider>
      <NvAddComponent
        enabled={mode === LecturePageMode.EDIT}
        noComponentsExist={!lectureComponents.length}
        index={lectureComponents.length}
      />
      {isPreview && isCopyOrMove && (
        <NvCopyComponent
          catalogId={params.catalogId}
          index={lectureComponents.length}
          currentLecturePageId={params.lecturePageId}
        />
      )}
    </div>
  );
});

// Wrapper component that determines whether to render a React or Angular component based on the trueType property. It also handles errors during rendering by using an ErrorBoundary to catch and display errors gracefully
const ComponentContainer: React.FC<LectureComponentProps<any>> = (props) => {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();
  const isReactLectureComponent = Boolean(reactComponentRenderFunctions[props.lectureComponent.trueType]);

  // If an error is caught, display a warning message
  if (didCatch) {
    console.error(error.message);
    return (
      <div className='d-flex justify-content-center align-items-center p-2 text-muted'>
        <NvIcon icon='warning' size='small' className='warning mr-2' />
        <span className='bold mr-1'>
          {t.FORM.OOPS()}
        </span>
        <span className='semi-bold'>
          {t.LECTURE_PAGES.COMPONENTS.ERROR()} {props.lectureComponent.trueType} ({props.lectureComponent.id})
        </span>
      </div>
    );
  }

  // Identifying if we are working with a React component
  if (isReactLectureComponent) {
    const ReactLectureComponent = reactComponentRenderFunctions[props.lectureComponent.trueType];

    return (
      <ErrorBoundary>
        <ReactLectureComponent {...props} />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <AngularLectureComponent {...props} />
    </ErrorBoundary>
  );
};

// Component to determine which component to render for the Discussion Insights project
const InsightsComponent: React.FC<{
  discussionComponent: LectureComponent<ComponentType.TOPIC>,
  isLearner: boolean,
}> = ({ discussionComponent, isLearner }) => {
  const dispatch = useAppDispatch();
  const topicId = discussionComponent?.topic?.id;
  const discussionInsights: AIDiscussionInsights = useSelector(
    (state) => getAIDiscussionInsights(state, topicId),
  );

  const hasAiInsights = discussionComponent?.topic?.hasAiInsights;
  const hasMinValidCommentsForKeyThemes = discussionComponent?.topic?.hasMinValidCommentsForKeyThemes;
  const hasKeyThemes = discussionInsights?.keyThemes?.data?.length > 0;
  const listeningPusher = discussionInsights?.keyThemes?.subscribeToPusher;
  const discussionHasComments = Boolean(discussionComponent?.commentsCount);
  const firstCommentId = discussionInsights?.firstCommentId;

  // Setting the discussion insights for the topic
  React.useEffect(() => {
    dispatch(updateDiscussionInsights({
      topicId,
      data: {
        firstCommentId: discussionComponent?.topic?.firstPostContributionId,
        keyThemes: {
          data: discussionComponent?.topic?.keyThemes,
        },
      },
    }));
  }, [topicId]);

  // Learner must have contributed to show the discussion insights
  if ((isLearner && firstCommentId) && (hasKeyThemes || listeningPusher)) {
    return (
      <DiscussionInsights
        isLearner={isLearner}
        topic={discussionComponent?.topic}
        hasAiInsights={hasAiInsights}
      />
    );
  }

  // Always show discussion insights to course admins if there are key themes or we are waiting for them
  if (!isLearner && (hasKeyThemes || listeningPusher)) {
    return (
      <DiscussionInsights
        isLearner={isLearner}
        topic={discussionComponent?.topic}
        hasAiInsights={hasAiInsights}
      />
    );
  }

  // Only show the placeholder to the learner if the discussion has comments and there are no insights
  if (isLearner) {
    if (!discussionHasComments) {
      return null;
    }
    return <CallOutBox icon='gen-ai' message={t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.PLACEHOLDER.LEARNER()} />;
  }

  // Show the placeholder to the admin if the threshold is not valid
  if (!hasMinValidCommentsForKeyThemes) {
    return <CallOutBox icon='gen-ai' message={t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.PLACEHOLDER.ADMIN()} />;
  }

  // Show the button to get the key theme to the admin
  return <GenerateDiscussionInsights topicId={topicId} />;
};

export default LectureComponentList;
