/* @ngInject */
export default function CommentModelService(

  CommentsResources,
  _,
  $sce,
  moment,
  ReplyModel,
  CurrentUserManager,
  AlertMessages,
) {
  const CommentModel = function (attributes) {
    const _this = this;

    _this.replyCount = 0;

    _.extend(_this, attributes);

    preprocess();

    /** Public Data * */
    _this.replies = [];

    _this.repliesFetched = false;
    _this.additionalRepliesBeforeCount = 0;
    _this.additionalNewRepliesBeforeCount = 0; // New replies within paginated data
    _this.additionalRepliesAfterCount = 0;

    _this.bodyTranslation = _this.body;
    _this.isTranslationOn = false;
    _this.isTranslationLoading = false;

    /** Public Functions * */

    _this.edit = edit;
    _this.update = update;

    _this.remove = remove;
    _this.highlight = highlight;
    _this.unHighlight = unHighlight;
    _this.like = like;
    _this.unlike = unlike;
    _this.follow = follow;
    _this.unfollow = unfollow;
    _this.report = report;
    _this.deleteReply = deleteReply;
    _this.updateReply = updateReply;
    _this.getCommentsCount = getCommentsCount;
    _this.getVotersFullNames = getVotersFullNames;
    _this.trustCommentContent = trustCommentContent;
    _this.formatCommentDate = formatCommentDate;
    _this.belongsToCurrentUser = belongsToCurrentUser;
    _this.getLikersInfo = getLikersInfo;
    _this.fetchReplies = fetchReplies;
    _this.onToggleTranslate = onToggleTranslate;

    /** Private Functions * */
    function preprocess() {
    }

    function edit() {
    }

    function update() {
      return CommentsResources.update({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {
        post: {
          topicId: _this.postId,
          body: _this.body,
          mentionedIds: _this.mentionedIds || null,
          includeVideoTimestamp: _this.includeVideoTimestamp,
          videoTimestamp: _this.videoTimestamp,
        },
      }).$promise;
    }


    function remove() {
      return CommentsResources.remove({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then(({ result: {
        peerEvaluation,
        owner: {
          progress,
          postsCount,
          pointsReceived,
          isPublicFeedbackOn,
          gavePublicFeedback,
          numPostsAndComments,
          firstPostContributionId,
        },
      } }) => {
        _.extend(this, { peerEvaluation });
        _.extend(this.owner, {
          progress,
          postsCount,
          pointsReceived,
          isPublicFeedbackOn,
          gavePublicFeedback,
          numPostsAndComments,
          firstPostContributionId,
        });
        return this;
      });
    }

    function highlight() {
      return CommentsResources.highlight({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then((response) => {
        _this.highlighted = true;
      });
    }

    function unHighlight() {
      return CommentsResources.unHighlight({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then(() => {
        _this.highlighted = false;
      });
    }

    function like() {
      return CommentsResources.like({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then((response) => {
        _this.liked = true;
        _this.votesCount = response.result.numLikes;
      });
    }

    function unlike() {
      return CommentsResources.unlike({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then((response) => {
        _this.liked = false;
        _this.votesCount = response.result.numLikes;
      });
    }

    function follow() {

    }

    function unfollow() {

    }

    function report() {
      return CommentsResources.report({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then(() => {
        _this.flagged = true;
      });
    }

    function deleteReply(reply) {
      _this.replies.splice(_.findIndex(_this.replies, { id: reply.id }), 1);
      _this.replyCount -= 1;
      _this.commented = _.some(_this.replies, (r) => r.user.id === CurrentUserManager.user.id);
      const member = _this.owner.type !== 'report' // no need to find contribution count for reports
        && _this.owner?.findMemberByUserId(reply.user.id);
      if (member) {
        member.totalContributions -= 1;
      }
    }

    function updateReply(reply) {
      return reply.update();
    }

    function getCommentsCount() {

    }

    function getVotersFullNames() {

    }

    function trustCommentContent() {
      _this.body = $sce.trustAsHtml(_this.body);
    }

    function formatCommentDate() {
      return moment(_this.createdAt).format(moment().isSame(_this.createdAt, 'year') ? 'MOMENT.MONTH_DAY_AT_TIME' : 'MOMENT.MONTH_DAY_COMMA_YEAR_AT_TIME');
    }

    function belongsToCurrentUser() {
      return _this.user && CurrentUserManager.user && _this.user.id === CurrentUserManager.user.id;
    }

    function getLikersInfo() {
      return CommentsResources.getLikersInfo({
        catalogId: _this.catalogId,
        id: _this.id,
        page: 1,
      }).$promise.then((response) => {
        _this.likers = response.result;
      });
    }

    function fetchReplies(beforeAfterData, pageSize) {
      const params = {
        catalogId: _this.catalogId,
        owner: _this,
        beforeId: beforeAfterData ? beforeAfterData.beforeId : null,
        afterId: beforeAfterData ? beforeAfterData.afterId : null,
        betweenId: beforeAfterData ? beforeAfterData.betweenId : null,
        pageSize,
        vieweeLastActivity: this.owner.vieweeLastActivity,
      };

      return ReplyModel.getRepliesForComment(params).then((response) => {
        _this.repliesFetched = true;

        if (response.result.additionalCommentsBeforeCount !== null) {
          _this.additionalRepliesBeforeCount = response.result.additionalCommentsBeforeCount;
          _this.additionalNewRepliesBeforeCount = response.result.additionalNewCommentsBeforeCount;
        }

        if (response.result.additionalCommentsAfterCount !== null) {
          _this.additionalRepliesAfterCount = response.result.additionalCommentsAfterCount;
        }

        return response.result;
      });
    }

    // eslint-disable-next-line consistent-return
    function onToggleTranslate(isStateOn, language) {
      _this.isTranslationOn = isStateOn;

      if (isStateOn) {
        _this.isTranslationLoading = true;

        return CommentsResources.translate(
          { catalogId: _this.catalogId, id: _this.id },
          { language },
        )
          .$promise.then(response => {
            _this.bodyTranslation = response.result.body;
            _this.isTranslationLoading = false;
          })
          .catch(() => {
            _this.isTranslationOn = false;
            _this.isTranslationLoading = false;
            AlertMessages.error(
              'DISCUSSIONS_AUTO_TRANSLATION.ERROR',
              'DISCUSSIONS_AUTO_TRANSLATION.RETRY',
            );
          });
      }
    }
  };

  CommentModel.getCommentsList = function (params) {
    const paramsToSend = _.omit(params, 'owner');
    // different endpoint for getting comments on a submission
    if (params.reportId) {
      return CommentsResources.getCommentsForSubmission(paramsToSend).$promise.then((resource) => ({
        ...(_.omit(resource.result, 'posts')),
        commentsList: processCommentsList(resource.result.posts),
      }));
    }
    return CommentsResources.getCommentsForPost(paramsToSend).$promise
      .then((resource) => _.extend(resource.result, {
        commentsList: processCommentsList(resource.result.posts),
      }));

    function processCommentsList(comments) {
      return _.map(comments, (comment) => {
        const newComment = new CommentModel(_.extend(comment, {
          catalogId: params.catalogId,
          owner: params.owner,
          replyCount: comment.commentsCount,
        }));


        return newComment;
      });
    }
  };

  CommentModel.getSingleComment = function (params) {
    return CommentsResources.getSingleComment({
      catalogId: params.catalogId,
      id: params.id,
    }, {}).$promise;
  };

  CommentModel.create = function (params) {
    const paramsKeys = ['catalogId', 'postId', 'ownerType', 'ownerId'];
    const filteredParams = _.pick(params, paramsKeys);

    if (params.ownerType === 'report') {
      return CommentsResources.saveToSubmission(
        filteredParams, {
          post: {
            body: params.content,
            mentionedIds: params.mentionedIds || null,
          },
        },
      ).$promise;
    }

    return CommentsResources.save(
      filteredParams, {
        post: {
          topicId: params.postId || null,
          body: params.content,
          mentionedIds: params.mentionedIds || null,
          includeVideoTimestamp: !!params.includeVideoTimestamp,
          videoTimestamp: params.videoTimestamp,
        },
      },
    ).$promise;
  };

  return CommentModel;
}
