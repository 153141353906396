import { CombinedPost } from '..';
import { TimelineExercise, Exercise } from './exercise';
import { TimelineExternalTool } from './external-tool';
import { TimelineGroupTeamSet } from './group-team-set';
import { ComponentTrueType, ComponentType, LectureComponent, RichTextType } from './lecture-component';
import { TimelineLectureVideo } from './lecture-video';
import { TimelineLiveSession } from './live-session';
import { TimelinePeerEvaluation, PeerEvaluation } from './peer-evaluation';
import { TimelineQuiz } from './quiz';
import { TimelineSurvey } from './survey';
import { TimelineTimedQuiz } from './timed-quiz';
import { VideoPracticeActivity } from './video-practice';
import { TeamSet } from './team-set';
import { VideoPracticeFeedbackActivity } from './video-practice-feedback';
import { TimelineProgressiveQuiz } from './progressive-quiz';
import { Poll, PollNormalized } from './poll';
import { ProfileRequirement } from './profile-requirement';
import { TeamDiscussion } from './team-discussion';
import { ExerciseSkillsRatingActivity } from './exercise-skills-rating';
import { TimelineUser } from './user';
import { Normalized } from '../normalized';
import { NovoAIItemType } from './lecture-page';

export enum ActivityType {
  // Course Material
  LECTURE_VIDEO = 'LectureVideo',

  // Submissions,
  EXERCISE = 'Exercise',
  QUIZ = 'Quiz',
  PROGRESSIVE_QUIZ = 'ProgressiveQuiz',
  TIMED_EXAM = 'TimedExam',
  SURVEY = 'Survey',

  // Social Activities
  TEAM_FORMATION = 'TeamSet',
  GROUP_FORMATION = 'GroupTeamSet',
  POST = 'Topic',
  TEAM_DISCUSSION = 'TeamDiscussion',
  PEER_EVALUATION = 'CustomQuestions',

  // Third Party Links & Tools
  EXTERNAL_TOOL = 'ExternalTool',
  LIVE_SESSION = 'LiveSession',

  PROFILE_REQUIREMENT = 'ProfileRequirement',

  POLL = 'Poll',

  VIDEO_PRACTICE = 'VideoPracticeActivity',

  // Rating Feedback
  EXERCISE_SKILLS_RATING = 'SkillsRatingFeedbackCriteria',
  VIDEO_PRACTICE_FEEDBACK = 'PracticeFeedbackCriteria',
  VIDEO_PRACTICE_SKILLS_FEEDBACK = 'SkillsRatingFeedbackCriteria',
}

export enum ActivityKey {
  CUSTOM_QUESTIONS = 'peerEvaluations',
  EXERCISE_SKILLS_RATING = 'exerciseSkillsRatingActivities',
  EXERCISES = 'exercises',
  EXTERNAL_TOOLS = 'externalTools',
  GROUP_TEAM_SET = 'groupTeamSets',
  LECTURE_VIDEOS = 'lectureVideos',
  LIVE_SESSION = 'liveSessions',
  POLLS = 'polls',
  PROFILE_REQUIREMENT = 'profileRequirements',
  QUIZZES = 'quizzes',
  SURVEYS = 'surveys',
  TEAM_DISCUSSION = 'teamDiscussions',
  TEAM_SET = 'teamSets',
  TIMED_QUIZZES = 'timedQuizzes',
  PROGRESSIVE_QUIZZES = 'progressiveQuizzes',
  TOPICS = 'posts',
  VIDEO_PRACTICE_FEEDBACK = 'practiceFeedbackActivities',
  VIDEO_PRACTICE_SKILLS_FEEDBACK = 'videoPracticeSkillsRating',
  VIDEO_PRACTICES = 'practiceActivities',
}

type MinimalActivityProgress = 'not_started' | 'in_progress' | 'completed' | 'missed';
type AssignmentActivityProgress = 'approval_needed' | 'pending' | 'rejected' | 'rejected_and_missed' | 'approved';
type EvaluationActivityProgress = 'started';
type LiveSessionActivityProgress = 'not_available' | 'attended_manual' | 'attended_auto' | 'watched_recording';

export type ActivityProgress = MinimalActivityProgress | AssignmentActivityProgress | EvaluationActivityProgress | LiveSessionActivityProgress;

export interface MinimalActivity {
  id: number;
  title: string;
  type: ActivityType;
  dueDate?: Date;
  isTodo: boolean;
  progress: ActivityProgress;
}

export interface TimelineActivity extends MinimalActivity {
  activityType: ActivityType; // OO-TODO: Remove this and use type ??
  lecturePageId: number;
  lectureComponentId: number;
  totalPoints: number[];
  pointsReceived: number;
  hardDeadline: boolean;
  released: boolean;
  isFeedbackPublic?: boolean;
}

export interface AwardedPoint {
  issuedDate: string;
  issuer: TimelineUser;
  reason: string;
  receivedPoints: number;
}

export interface ActivityNormalized { [id: string]: MinimalActivity }

/** TODO: New 'Payload' types as of 2/5/21. possibly needs  moved elsewhere + reconciled with the above */
export type AttachmentPayload = {
  attachment: Object, // TODO: Define a type for this
};

export type AudioPayload = VideoPayload;

export type ExercisePayload = {
  exercise: TimelineExercise;
  numSubmissions: number,
};

export type ExternalToolPayload = {
  externalTool: TimelineExternalTool;
};

export type GroupFormationPayload = {
  groupTeamSet: TimelineGroupTeamSet;
  // I believe both sets exist on groups since the GroupFormationLectureComponentModel references
  // `teamSet.name`
  teamSet: TimelineGroupTeamSet;
};

export type LiveSessionPayload = {
  liveSession: TimelineLiveSession;
  liveSessionId?: number; // TODO: Delete
};

export type PollPayload = {
  poll: PollNormalized, // TODO: Likely wrong, fix when transplanting poll component
};

export type PrivatePeerEvaluationPayload = {
  peerEvaluation: TimelinePeerEvaluation;
};

export type ProfileCompletionPayload = {
  profileRequirement: ProfileRequirement;
  profileRequirementId?: number; // TODO: Delete
  redirectToAdminNavigation?: boolean;
};

export type PublicPeerEvaluationPayload = PrivatePeerEvaluationPayload;

export type QuizPayload = {
  quiz: TimelineQuiz;
  quizPath: string;
};

export type ProgressiveQuizPayload = {
  progressiveQuiz: TimelineQuiz & {
    questionMaximumAttempts: number;
  };
};

export type NProgressiveQuizPayload = {
  progressiveQuiz: Normalized<TimelineQuiz, 'pointsConfiguration'> & {
    questionMaximumAttempts: number;
    totalQuestions: number;
  };
};


export type SurveyPayload = {
  survey: TimelineSurvey;
  surveyPath: string;
};

export type TeamDiscussionPayload = {
  teamDiscussion: TeamDiscussion;
  teamDiscussionId: number; // TODO: delete
  post: CombinedPost;
  topic: CombinedPost;
};

export type TeamFormationPayload = {
  teamSet: TimelineGroupTeamSet;
};

export type TimedQuizPayload = {
  quiz: TimelineTimedQuiz;
  quizPath: string;
};

export type TopicPayload = {
  commentsCount: number;
  topic: CombinedPost;
  post: CombinedPost; // TODO: Figure out what is appropriate here
  teamDiscussion?: TeamDiscussion;
};

export type VideoPayload = {
  lectureVideos: TimelineLectureVideo[];
};

export type VideoPracticePayload = {
  practiceActivity: VideoPracticeActivity;
};

export type VideoPracticeFeedbackPayload = {
  practiceFeedbackActivity: VideoPracticeFeedbackActivity;
};

export type ExerciseSkillsRatingPayload = {
  exerciseSkillsRatingActivity: ExerciseSkillsRatingActivity
};

// Disable eslint to preserve formatting
/* eslint-disable */

/** Represents the "extra data" attached to a lecture component response that is dependant on the specific type of LC
 * in the response. So, Exercise lecture components have 'exercise' defined but not 'liveSession', 'teamDiscussion', etc.
 * This is somewhat similar to the 'ComponentKey' type created for Course Communications but this type
 * has implications on what properties belong to a component instead of just identifying the type.
 *
 * Note that 'payload' as a name here is somewhat problematic; not only do the old Angularjs lecture component models have 'getPayload()' functions
 * that select a number of props off the LectureComponent object, our `getPayload()` selector actually returns the properties *inside*
 * these Payload types. So getPayload() with a LectureComponent<ComponentType.Video> will return `lectureVideos`, instead of an object
 * containing `lectureVideos`. */
export type LectureComponentPayload<C extends ComponentType> =
  C extends ComponentType.ATTACHMENT ? AttachmentPayload
  : C extends ComponentType.AUDIO ? AudioPayload
  : C extends ComponentType.EXERCISE ? ExercisePayload
  : C extends ComponentType.EXERCISE_SKILLS_RATING ? ExerciseSkillsRatingPayload
  : C extends ComponentType.EXTERNAL_TOOL ? ExternalToolPayload
  : C extends ComponentType.GROUP_FORMATION ? GroupFormationPayload
  : C extends ComponentType.LIVE_SESSION ? LiveSessionPayload
  : C extends ComponentType.POLL ? PollPayload
  : C extends ComponentType.PRIVATE_PEER_EVALUATION ? PrivatePeerEvaluationPayload
  : C extends ComponentType.PROFILE_COMPLETION ? ProfileCompletionPayload
  : C extends ComponentType.PROGRESSIVE_QUIZ ? ProgressiveQuizPayload
  : C extends ComponentType.PUBLIC_PEER_EVALUATION ? PublicPeerEvaluationPayload
  : C extends ComponentType.QUIZ ? QuizPayload
  : C extends ComponentType.SURVEY ? SurveyPayload
  : C extends ComponentType.TEAM_DISCUSSION ? TeamDiscussionPayload
  : C extends ComponentType.TEAM_FORMATION ? TeamFormationPayload
  : C extends ComponentType.TIMED_QUIZ ? TimedQuizPayload
  : C extends ComponentType.TOPIC ? TopicPayload
  : C extends ComponentType.VIDEO ? VideoPayload
  : C extends ComponentType.VIDEO_PRACTICE ? VideoPracticePayload
  : C extends ComponentType.VIDEO_PRACTICE_FEEDBACK ? VideoPracticeFeedbackPayload
  : C extends ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK ? ExerciseSkillsRatingPayload
  : {};

export type LectureComponentAIPayload<C extends ComponentType> =
  C extends RichTextType.SIMPLE ? SummaryAndKeyTakeawaysPayload
  : C extends ComponentType.TEXT_WITH_IMAGE_SIDE ? SummaryAndKeyTakeawaysPayload
  : C extends ComponentType.TEXT_WITH_IMAGE_BKG ? SummaryAndKeyTakeawaysPayload
  : C extends ComponentType.TEXT_WITH_IMAGE_TOP ? SummaryAndKeyTakeawaysPayload
  : {};
/* eslint - enable */

export type SummaryAndKeyTakeawaysPayload = {
  length: string,
  tone: string,
};

export type NovoAIPayload<C extends ComponentType> = {
  aiAction: NovoAIItemType,
  aiOriginTarget?: {
    type: string,
    id: number
  },
} & LectureComponentAIPayload<C>;

export enum NovoAIErrorCodes {
  TOO_SHORT = 'content_too_short',
  BLANK = 'blank_text_extract',
}

/** A runtime lookup table for determining which LectureComponent property has the Payload data.
 * Some Payload objects have 'extra' info, like how Exercise has a `numSubmissions` prop, so this eliminates those
 * NOTE: This should be of type Partial<{ [C in ComponentType]: keyof LectureComponentPayload<C> }>
 * I have not found a way in TS to both declare constraints on this *and* have it be data, even if const */
export const innerPayloadKey = {
  [ComponentType.AUDIO]: 'lectureVideos',
  [ComponentType.EXERCISE_SKILLS_RATING]: 'skillsRating',
  [ComponentType.EXERCISE]: 'exercise',
  [ComponentType.EXTERNAL_TOOL]: 'externalTool',
  [ComponentType.GROUP_FORMATION]: 'groupTeamSet',
  [ComponentType.LIVE_SESSION]: 'liveSession',
  [ComponentType.POLL]: 'poll',
  [ComponentType.PRIVATE_PEER_EVALUATION]: 'peerEvaluation',
  [ComponentType.PROFILE_COMPLETION]: 'profileRequirement',
  [ComponentType.PUBLIC_PEER_EVALUATION]: 'peerEvaluation',
  [ComponentType.QUIZ]: 'quiz',
  [ComponentType.PROGRESSIVE_QUIZ]: 'progressiveQuiz',
  [ComponentType.SURVEY]: 'survey',
  [ComponentType.TEAM_DISCUSSION]: 'topic',
  [ComponentType.TEAM_FORMATION]: 'teamSet',
  [ComponentType.TIMED_QUIZ]: 'quiz',
  [ComponentType.TOPIC]: 'topic',
  [ComponentType.VIDEO_PRACTICE_FEEDBACK]: 'publicFeedback',
  [ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK]: 'skillsRating',
  [ComponentType.VIDEO_PRACTICE]: 'videoPractice',
  [ComponentType.VIDEO]: 'lectureVideos',
} as const;

export type InnerPayloadKey = typeof innerPayloadKey;

/**
 * "innerPayloadKey" constant is for usage against the backend (the property
 * values are what backend expects for creation or modification of the lecture
 * component), this other constant is for local frontend usage (we name some
 * slightly different while storing them on redux).
 */
export const feInnerPayloadKey = {
  ...innerPayloadKey,
  [ComponentType.TOPIC]: 'post',
  [ComponentType.TEAM_DISCUSSION]: 'post',
  [ComponentType.TIMED_QUIZ]: 'timedQuiz',
  [ComponentType.VIDEO_PRACTICE]: 'practiceActivity',
  [ComponentType.VIDEO_PRACTICE_FEEDBACK]: 'practiceFeedbackActivity',
  [ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK]: 'exerciseSkillsRatingActivity',
} as const;

export type FEInnerPayloadKey = typeof feInnerPayloadKey;

/** This type is intended to get us the correct return type for getPayload() but doesn't seem to be working in many cases as getPayload()
 * often is shown as returning 'any'. It may be easier to just remove this as it's very complex  */
// Please ignore this ts-ignore: there's a bug in TypeScript (I think similar to this: https://github.com/microsoft/TypeScript/issues/21760) where TS tells us we cannot
// index LCP with InnerPayloadKey[C]. But, the type derivation actually works 100% OK
// @ts-ignore
export type InnerPayload<C extends ComponentType, LCP = LectureComponentPayload<C>, IPK = C extends keyof InnerPayloadKey ? LCP[InnerPayloadKey[C]] : null> = IPK;

export enum ResetSubmissionType {
  QUIZ = 'Quiz',
  TIMED_EXAM = 'TimedExam',
  SURVEY = 'Survey',
  VIDEO_QUIZ = 'VideoQuiz',
  POLL = 'Poll',
  PRACTICE = 'VideoPracticeActivity',
  PROGRESSIVE_QUIZ = 'ProgressiveQuiz',
}

export interface SubmissionsAttempt {
  id: number;
  type: ResetSubmissionType;
  title: string;
  hardDeadlinePassed: boolean;
  attempted: boolean;
}

export type ActivityTodoWithDeadline =
  Exercise
  | TimelineSurvey
  | TimelineTimedQuiz
  | TimelineQuiz
  | Poll
  | VideoPracticeActivity
  | PeerEvaluation
  | TeamSet
  | VideoPracticeFeedbackActivity
  | ExerciseSkillsRatingActivity
  | TimelineProgressiveQuiz;
