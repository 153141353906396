import { createReducer } from '@reduxjs/toolkit';
import {
  enableInsightsToLearners,
  fetchAIGeneratedPrompts,
  fetchSinglePost,
  getKeyThemes,
  getPerspectives,
  updateDiscussionInsights,
  resetDiscussionInsights,
} from 'redux/actions/posts';
import { NovoAIErrorCodes } from 'redux/schemas/models/activity';
import { AIDiscussionInsights, AIErrorPayload } from 'redux/schemas/models/post';
import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import { initialRootState } from '.';

export const initialDiscussionInsights: AIDiscussionInsights = {
  keyThemes: {
    loading: false,
    subscribeToPusher: false,
    data: [],
    errorCode: null,
  },
  perspectives: {
    loading: false,
    similar: {},
    different: {},
    errorCode: null,
  },
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(fetchSinglePost.fulfilled, (state, action) => {
      const { postId } = action.meta.arg;
      const post = action.payload;
      Object.assign(state.models.posts[postId], post);
    })
    .addCase(fetchAIGeneratedPrompts.rejected, (state) => {
      state.app.generatedAIContent.discussionPrompts.loading = false;
    })
    .addCase(fetchAIGeneratedPrompts.pending, (state) => {
      state.app.generatedAIContent.discussionPrompts.loading = true;
    })
    .addCase(fetchAIGeneratedPrompts.fulfilled, (state, action) => {
      state.app.generatedAIContent.discussionPrompts.loading = false;
      state.app.generatedAIContent.discussionPrompts.errorCode = action.payload.errorCode;
      state.app.generatedAIContent.discussionPrompts.prompts = action.payload.prompts;
    })
    .addCase(resetDiscussionInsights, (state, { payload }) => {
      const { topicId } = payload;
      state.app.generatedAIContent.discussionInsights[topicId] = {
        firstCommentId: null,
        ...initialDiscussionInsights,
      };
    })
    .addCase(updateDiscussionInsights, (state, { payload }) => {
      const { topicId, data } = payload;
      state.app.generatedAIContent.discussionInsights[topicId] = {
        firstCommentId: data?.firstCommentId,
        keyThemes: {
          ...initialDiscussionInsights.keyThemes,
          ...state.app.generatedAIContent.discussionInsights[topicId]?.keyThemes,
          data: data?.keyThemes?.data || state.app.generatedAIContent.discussionInsights[topicId]?.keyThemes?.data,
        },
        perspectives: {
          ...initialDiscussionInsights.perspectives,
          ...state.app.generatedAIContent.discussionInsights[topicId]?.perspectives,
        },
      };
    })
    .addCase(enableInsightsToLearners.fulfilled, (state, { meta, payload }) => {
      const { discussionId, hasAiInsights } = meta.arg;
      if (payload) {
        const lectureComponent = state.models.lectureComponents[discussionId] as unknown as LectureComponent<ComponentType.TOPIC>;
        lectureComponent.topic.hasAiInsights = hasAiInsights;
      }
    })
    .addCase(getKeyThemes.rejected, (state, { meta, payload }) => {
      const { topicId } = meta.arg;
      state.app.generatedAIContent.discussionInsights[topicId].keyThemes.loading = false;
      state.app.generatedAIContent.discussionInsights[topicId].keyThemes.errorCode = payload as NovoAIErrorCodes;
    })
    .addCase(getKeyThemes.pending, (state, { meta }) => {
      const { topicId } = meta.arg;
      state.app.generatedAIContent.discussionInsights[topicId].keyThemes.loading = true;
      state.app.generatedAIContent.discussionInsights[topicId].keyThemes.errorCode = null;
    })
    .addCase(getKeyThemes.fulfilled, (state, { meta, payload }) => {
      const { topicId } = meta.arg;
      const keyThemes = payload.result;

      state.app.generatedAIContent.discussionInsights[topicId].keyThemes.loading = false;
      state.app.generatedAIContent.discussionInsights[topicId].keyThemes.errorCode = null;

      if (payload.status === 207) {
        state.app.generatedAIContent.discussionInsights[topicId].keyThemes.subscribeToPusher = true;
      } else {
        state.app.generatedAIContent.discussionInsights[topicId].keyThemes.subscribeToPusher = false;
        state.app.generatedAIContent.discussionInsights[topicId].keyThemes.data = keyThemes;
        state.models.posts[topicId].keyThemes = keyThemes;
      }
    })
    .addCase(getPerspectives.rejected, (state, { meta, payload }) => {
      const { topicId } = meta.arg;
      state.app.generatedAIContent.discussionInsights[topicId].perspectives.loading = false;
      state.app.generatedAIContent.discussionInsights[topicId].perspectives.errorCode = (payload as AIErrorPayload)?.code;
    })
    .addCase(getPerspectives.pending, (state, { meta }) => {
      const { topicId } = meta.arg;
      state.app.generatedAIContent.discussionInsights[topicId].perspectives = {
        ...initialDiscussionInsights.perspectives,
        loading: true,
        errorCode: null,
      };
    })
    .addCase(getPerspectives.fulfilled, (state, { meta, payload }) => {
      const { topicId } = meta.arg;
      state.app.generatedAIContent.discussionInsights[topicId].perspectives.loading = false;
      state.app.generatedAIContent.discussionInsights[topicId].perspectives.errorCode = null;

      if (payload.similarReply) {
        state.app.generatedAIContent.discussionInsights[topicId].perspectives.similar = payload.similarReply;
      }
      if (payload.differentReply) {
        state.app.generatedAIContent.discussionInsights[topicId].perspectives.different = payload.differentReply;
      }
    });
});
